import Form from "../form/Form";

const Contact = () => {
  return (
    <section id="contact">
      <h2 id="cntct-ttl">
        「ITひつじ」に関するお問い合わせは
        <br />
        こちらまで
      </h2>
      <p className="note">※下記の項目を入力してください</p>
      <Form />
    </section>
  );
};

export default Contact;

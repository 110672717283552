import step from "../../assets/img/step.svg";

const Flow = () => {
  return (
    <section id="flow">
      <div className="top-inner">
        <h2 className="top-h2ttl top-h2ttl__ub">
          <span>導入</span>までの流れ
        </h2>
        <p className="top-lead">
          以下の流れは一般的な流れになります。​
          <br />
          まずはヒアリングさせて頂き、御社に最適な方法にて「ITひつじ」を導入させて頂きます。
        </p>
        <p id="step">
          <img src={step} alt="" />
        </p>
        <sub>※お客様のITのご状況により内容が変わる場合がございます。</sub>
      </div>
    </section>
  );
};

export default Flow;

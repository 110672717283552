import logo from "../assets/img/logo.svg";

const Header = () => {
  return (
    <header>
      <div id="header-in">
        <div id="h-left">
          <h1>
            <a href="">
              <img src={logo} alt="" />
            </a>
          </h1>
          <p>
            「ITひつじ」はITコーディネータによる
            <br />
            情報システムアウトソーシングサービスです。
          </p>
        </div>
        <nav id="h-right">
          {/* <p id="h-blog">
            <a href="">ITひつじ<br />ブログ</a>
          </p> */}
          <address id="h-tel">
            <p>
              050-5539-7550<span>(平日 9:30～17:30)</span>
            </p>
          </address>
          <p id="h-mail">
            <a href="#contact">資料請求</a>
          </p>
        </nav>
      </div>
    </header>
  );
};

export default Header;

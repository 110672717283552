import logo01 from "../../assets/img/icon_logo01.svg";
import logo02 from "../../assets/img/icon_logo02.svg";
import logo03 from "../../assets/img/icon_logo03.svg";
import logo04 from "../../assets/img/icon_logo04.svg";
import logo05 from "../../assets/img/icon_logo05.svg";
import logo06 from "../../assets/img/icon_logo06.svg";
import logo07 from "../../assets/img/icon_logo07.svg";
import logo08 from "../../assets/img/icon_logo08.svg";

const Case = () => {
  return (
    <>
      <section id="case">
        <h2 className="top-h2ttl top-h2ttl__ub">
          <span>実績</span>紹介
        </h2>
        <p className="top-lead">
          「ITひつじ」をご導入頂きました、お客様のご紹介です。
          <br />
          様々な専門業種のお客様にご導入頂き、本業の業務に専念されております。
        </p>
        <ul id="case-list">
          <li>
            <dl>
              <dt>東京都IT関連営業会社様</dt>
              <dd>
                <ul>
                  <li>
                    <span>●</span>
                    会社の立ち上がり時にMicrosoft365の導入設計、支援、AzureAD運用
                  </li>
                  <li>
                    <span>●</span>社内ネットワーク配線工事
                  </li>
                  <li>
                    <span>●</span>社内情報システムヘルプデスク
                  </li>
                  <li>
                    <span>●</span>PC導入支援(Surface,中古PCをSSD導入し再利用)
                  </li>
                  <li>
                    <span>●</span>社内電話クラウドPBX導入支援
                  </li>
                </ul>
              </dd>
            </dl>
          </li>
          <li className="dsh-bd-btm">
            <dl>
              <dt>神奈川県人材サービス会社様</dt>
              <dd>
                <ul>
                  <li>
                    <span>●</span>IT導入補助金申請支援
                  </li>
                  <li>
                    <span>●</span>社内情報システムヘルプデスク
                  </li>
                  <li>
                    <span>●</span>PC導入支援
                  </li>
                  <li>
                    <span>●</span>情報セキュリティマネジメント体制構築支援
                  </li>
                </ul>
              </dd>
            </dl>
          </li>
          <li className="dsh-bd-btm">
            <dl>
              <dt>東京都コンサルティング会社様</dt>
              <dd>
                <ul>
                  <li>
                    <span>●</span>Microsoft365/AzureAD導入設計、支援
                  </li>
                  <li>
                    <span>●</span>セキュリティ診断、改善、運用
                  </li>
                  <li>
                    <span>●</span>Webシステムシステム企画、開発、運用
                  </li>
                </ul>
              </dd>
            </dl>
          </li>
        </ul>
        <div id="top-logoarea">
          <h3>
            <span>様々な資格</span>を保有したITひつじが在籍
          </h3>
          <ul>
            <li>
              <img className="logo1" src={logo01} alt="" />
              <div className="balloon balloon1">
                <dl>
                  <dt>ITコーディネータ</dt>
                  <dd>
                    真に経営に役立つIT利活用に向け、経営者の立場に立った助言・支援を行い、IT経営を実現する人材です。
                  </dd>
                </dl>
              </div>
            </li>
            <li>
              <img className="logo2" src={logo02} alt="" />
              <div className="balloon balloon2">
                <dl>
                  <dt>Microsoft Azure Fundamentals</dt>
                  <dd>
                    クラウド サービスの基礎知識と、それらのサービスが Microsoft
                    Azure
                    でどのように提供されるかについての知識を習得している専門家です。
                  </dd>
                </dl>
              </div>
            </li>
            <li>
              <img className="logo3" src={logo03} alt="" />
              <div className="balloon balloon3">
                <dl>
                  <dt>Microsoft 365 Fundamentals</dt>
                  <dd>
                    市場での競合から Microsoft 365
                    ソリューションを差別化するために必要な知識を持っている専門家です。
                  </dd>
                </dl>
              </div>
            </li>
            <li>
              <img className="logo4" src={logo04} alt="" />
              <div className="balloon balloon4">
                <dl>
                  <dt>AI・IoT Master Consultant</dt>
                  <dd>
                    AI×IoTの相互の面で企業が抱える様々な経営課題をAIやIoTツールを活用し、課題解決できる専門家です。
                  </dd>
                </dl>
              </div>
            </li>
            <li>
              <img className="logo5" src={logo05} alt="" />
              <div className="balloon balloon5">
                <dl>
                  <dt>中小企業診断士</dt>
                  <dd>
                    中小企業の経営課題に対応するための診断・助言を行う専門家です。
                  </dd>
                </dl>
              </div>
            </li>
            <li>
              <img className="logo6" src={logo06} alt="" />
              <div className="balloon balloon6">
                <dl>
                  <dt>kintone CERTIFIED Associate</dt>
                  <dd>
                    kintoneの機能および設定方法に関する体系的な知識を持ち、kintoneを活用した業務改善スキルがある専門家です。
                  </dd>
                </dl>
              </div>
            </li>
            <li>
              <img className="logo7" src={logo07} alt="" />
              <div className="balloon balloon7">
                <dl>
                  <dt>G検定</dt>
                  <dd>
                    ディープラーニングの基礎知識を有し、適切な活用方針を決定して、事業活用する能力や知識を有する専門家です。
                  </dd>
                </dl>
              </div>
            </li>
            <li>
              <img className="logo8" src={logo08} alt="" />
              <div className="balloon balloon8">
                <dl>
                  <dt>共通EDI推進サポータ</dt>
                  <dd>
                    「中小企業商流・金流EDIコーディネータ導入支援者育成カリキュラム」に基づき育成された共通EDIの専門家です。
                  </dd>
                </dl>
              </div>
            </li>
          </ul>
        </div>
      </section>
    </>
  );
};

export default Case;

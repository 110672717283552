import axios from "axios";
import { useState } from "react";

const Form = () => {
  const [formData, setFormData] = useState({
    companyName: "",
    pic: "",
    tel: "",
    mail: "",
    inquiry: "",
  });

  const [errors, setErrors] = useState({
    companyName: "",
    pic: "",
    tel: "",
    mail: "",
    inquiry: "",
  });

  const [modalContent, setModalContent] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  /**
   * バリデーションルール
   */
  const validateInput = (name, value) => {
    if (name === "companyName" && value.trim() === "") {
      return "会社名を入力してください。";
    }

    if (name === "pic" && value.trim() === "") {
      return "ご担当者名を入力してください。";
    }

    if (name === "tel") {
      if (value.trim() === "") {
        return "電話番号を入力してください。";
      }

      const invalidCharsRegex = /[^\d-]/;
      if (invalidCharsRegex.test(value)) {
        return "電話番号は半角数字とハイフンのみで入力してください。";
      }

      if (/^\d/.test(value) && !/^0/.test(value)) {
        return "電話番号は0から始まる必要があります。";
      }

      const numericValue = value.replace(/\D/g, "");
      if (numericValue.length < 10 || numericValue.length > 11) {
        return "電話番号は10桁または11桁で入力してください。";
      }
    }

    if (name === "mail") {
      if (value.trim() === "") {
        return "メールアドレスを入力してください。";
      }
      const emailRgax = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRgax.test(value)) {
        return "メールアドレスの形式を確認してください。";
      }
    }

    if (name === "inquiry" && value.trim() === "") {
      return "お問い合わせ内容を入力してください。";
    }

    return "";
  };

  /**
   * onChangeハンドル
   */
  const handleChange = (e) => {
    const { name, value } = e.target;
    const errorMessage = validateInput(name, value);
    setErrors({ ...errors, [name]: errorMessage || "" });
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  /**
   * 全項目にバリデーションを走らせる為の関数（onSubmit時に使用する）
   */
  const isFormValid = () => {
    const errorsCopy = { ...errors };
    let isValid = true;

    // すべての入力項目に対してバリデーションを実行
    Object.keys(formData).forEach((name) => {
      const errorMessage = validateInput(name, formData[name]);
      errorsCopy[name] = errorMessage;

      if (errorMessage) {
        isValid = false;
      }
    });

    // エラーがあればセットし、なければクリア
    setErrors(errorsCopy);

    return isValid;
  };

  /**
   *  送信ハンドル
   */
  const handleSubmit = async (e) => {
    e.preventDefault();

    const isValid = isFormValid();

    if (!isValid) {
      console.log("フォームが正しく入力されていません。");
      return;
    }

    try {
      const response = await axios.post(
        // "https://ithirsujiresource.azure-api.net/funcITHitsuji/Trigger",
        "https://ithitsujifunction-apim.azure-api.net/ITHitsujiFunction/http_trigger",
        formData,
        {
          headers: {
            // "Ocp-Apim-Subscription-Key": "660bc639901249af88070b6a92eb63b3",
            "Ocp-Apim-Subscription-Key": "741da32eb63e41debcd545a594555165",
          },
        }
      );
      // console.log(response);
      if (response.status === 200) {
        setModalContent(true);
      } else {
        console.log(response.status, response.statusText);
        setModalContent(false);
      }
      setIsModalOpen(true);
    } catch (error) {
      console.error("error: ", error.message);
    }
  };

  /**
   * モーダルの閉じるボタンを押したときの処理
   */
  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        <dl>
          <dt>会社名</dt>
          <dd>
            <input
              type="text"
              name="companyName"
              id="companyName"
              onChange={handleChange}
            />
            {errors.companyName && (
              <p className="error">{errors.companyName}</p>
            )}
          </dd>
          <dt>ご担当者名</dt>
          <dd>
            <input type="text" name="pic" id="pic" onChange={handleChange} />
            {errors.pic && <p className="error">{errors.pic}</p>}
          </dd>
          <dt>電話番号</dt>
          <dd>
            <input
              type="text"
              name="tel"
              id="tel"
              placeholder="例：03-1234-5678"
              onChange={handleChange}
            />
            {errors.tel && <p className="error">{errors.tel}</p>}
          </dd>
          <dt>メールアドレス</dt>
          <dd>
            <input type="text" name="mail" id="mail" onChange={handleChange} />
            {errors.mail && <p className="error">{errors.mail}</p>}
          </dd>
          <dt>お問い合わせ内容</dt>
          <dd>
            <textarea
              name="inquiry"
              id="inquiry"
              cols="30"
              rows="10"
              onChange={handleChange}
            ></textarea>
            {errors.inquiry && <p className="error">{errors.inquiry}</p>}
          </dd>
        </dl>
        <p id="cntct-btn">
          <input type="submit" value="送信" />
        </p>
      </form>
      {isModalOpen && (
        <div className="modal">
          <div className="modal-content">
            {modalContent === true ? (
              <div className="message complete">
                <i className="fas fa-check-circle"></i>
                <p className="txt">担当者よりご連絡いたしますので、<br />今しばらくお待ちください。</p>
                <p className="supplemental"></p>
              </div>
            ) : (
              <div className="message failed">
                <i className="fas fa-exclamation-triangle"></i>
                <p className="txt">送信できませんでした</p>
                <p className="supplemental">
                  入力内容をお確かめの上、もう一度送信ボタンをクリックしてください。
                </p>
              </div>
            )}
            <button onClick={handleModalClose}>閉じる</button>
          </div>
        </div>
      )}
    </>
  );
};

export default Form;

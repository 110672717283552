import Case from "./sections/Case";
import Contact from "./sections/Contact";
import Feature from "./sections/Feature";
import Flow from "./sections/Flow";
import Mv from "./sections/Mv";
import Plan from "./sections/Plan";

const Main = () => {
  return (
    <>
      <Mv />
      <Feature />
      <Case />
      <Plan />
      <Flow />
      <Contact />
    </>
  );
};

export default Main;

const Feature = () => {
  return (
    <>
      <section id="feature">
        <div className="top-inner">
          <h2 className="top-h2ttl">
            <span>ITひつじ</span>が、御社のITのお世話をいたします。
          </h2>
          <p className="top-lead">
            『ITひつじ』は経済産業省推進資格ITコーディネータの資格取得者のみで構成され、
            <br />
            裏付けされた知識や経験を発揮し、皆さまの環境に合わせた最適なご提案が可能です。
          </p>
          <article className="flex-wrapper">
            <dl>
              <dt>
                日々のIT運用業務から解放
                <br />
                本業に専念できます。
              </dt>
              <dd>
                日々のルーチンワーク等の業務を「ITひつじ」が対応いたします。御社は本業に専念できるため生産性の向上が期待できます。
              </dd>
            </dl>
            <dl>
              <dt>
                社内業務のデジタル化を
                <br />
                進めます。
              </dt>
              <dd>
                様々なツールや開発に精通した「ITひつじ」が、御社にぴったりのデジタル化案をご提案できます。
              </dd>
            </dl>
            <dl>
              <dt>
                様々な業種のITに強い人材が
                <br />
                多数所属しております。
              </dt>
              <dd>
                様々なITのジャンルに精通した「ITひつじ」の精鋭が所属しております​。またITだけではなく、中小企業診断士や税理士等幅広いジャンルの人材が所属しておりご提案可能です。
              </dd>
            </dl>
            <dl>
              <dt>
                情報システム担当の
                <br />
                退職リスクを軽減できます。
              </dt>
              <dd>
                情報システム担当の作業は属人化し、退職時にあたふたする場合が多いです。ITひつじでは一貫したサービスのご提供が可能です。
              </dd>
            </dl>
            <dl>
              <dt>
                セキュリティに関する
                <br />
                ご相談も承ります。
              </dt>
              <dd>
                SECURITY
                ACTIONやプライバシーマークの資格取得/運用等もご相談頂けます。​
              </dd>
            </dl>
            <dl>
              <dt>
                助成金や補助金の専門家が
                <br />
                申請をご支援いたします。
              </dt>
              <dd>
                補助金/助成金に精通した「ITひつじ」が多数おり、様々なご提案が可能です。​
              </dd>
            </dl>
          </article>
        </div>
      </section>
    </>
  );
};

export default Feature;

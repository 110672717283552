import fifty_per from "../../assets/img/50per_cp.svg";
import price_plan from "../../assets/img/price_plan.svg";

const Plan = () => {
  return (
    <section id="plan">
      <div className="top-inner">
        <h2 className="top-h2ttl top-h2ttl__ub">
          <span>価格</span>について
        </h2>
        <p className="top-lead">
          価格については原則管理PCの台数にて決定させて頂きます。
          <br />
          またそれ以外のサーバやIoT機器等、ご調整可能ですのでお気軽にご相談ください。​
        </p>
        <p id="top-cpn">
          <img src={fifty_per} alt="" />
        </p>
        <p id="top-plantbl">
          <img src={price_plan} alt="" />
        </p>
      </div>
    </section>
  );
};

export default Plan;

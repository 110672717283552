import pagetop from "../assets/img/pagetop.svg";
import logo from "../assets/img/logo.svg";
import { useEffect } from "react";

const Footer = () => {
  useEffect(() => {
    //スムーススクロール
    const smoothScrollTrigger = document.querySelectorAll('a[href^="#"]');
    for (let i = 0; i < smoothScrollTrigger.length; i++) {
      smoothScrollTrigger[i].addEventListener("click", (e) => {
        e.preventDefault();
        let href = smoothScrollTrigger[i].getAttribute("href");
        let targetElement = document.getElementById(href.replace("#", ""));
        const rect = targetElement.getBoundingClientRect().top;
        const offset = window.pageYOffset;
        const gap = 60;
        const target = rect + offset - gap;
        window.scrollTo({
          top: target,
          behavior: "smooth",
        });
      });
    }

    //スクロール量を取得する関数
    function getScrolled() {
      return window.pageYOffset !== undefined
        ? window.pageYOffset
        : document.documentElement.scrollTop;
    }

    //トップに戻るボタンの要素を取得
    var topButton = document.getElementById("js-scroll-fadein");

    //ボタンの表示・非表示
    window.onscroll = function () {
      getScrolled() > 500
        ? topButton.classList.add("is-fadein")
        : topButton.classList.remove("is-fadein");
    };

    //トップに移動する関数
    function scrollToTop() {
      var scrolled = getScrolled();
      window.scrollTo(0, Math.floor(scrolled / 2));
      if (scrolled > 0) {
        window.setTimeout(scrollToTop, 30);
      }
    }

    //イベント登録
    topButton.onclick = function () {
      scrollToTop();
    };
  }, []);

  return (
    <>
      <div id="js-scroll-fadein" className="js-scroll-fadein arrow">
        <img src={pagetop} alt="" />
      </div>
      <footer>
        <div id="footer-in">
          <p className="logo">
            <a href="">
              <img src={logo} alt="" />
            </a>
          </p>
          <p id="footer-about">
            ITひつじはITコーディネータで構成されたサービスです。
            <br />
            DXに必要な補助金・助成金申請やコンサルティング、導入、運用まで幅広くお任せください。
            <br />
            <span>営業時間：9:30～17:30(平日)</span>
          </p>
        </div>
        <p id="footer-copy">Copyright ⓒ 2020 ITひつじ ALL RIGHTS RESERVED.</p>
      </footer>
    </>
  );
};

export default Footer;

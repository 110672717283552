// import mv from "../../assets/img/mv.svg";
// import mv from "../../assets/img/mv.jpg";
import mv from "../../assets/img/mv.png";

const Mv = () => {
  return (
    <section id="mv">
      <p>
        <img src={mv} alt="" />
      </p>
    </section>
  );
};
export default Mv;
